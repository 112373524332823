.button-17 {
  align-items: center;
  appearance: none;
  background-color: #fff;
  border-radius: 24px;
  border-style: none;
  box-shadow: rgba(0, 0, 0, 0.2) 0 3px 5px -1px,
    rgba(0, 0, 0, 0.14) 0 6px 10px 0, rgba(0, 0, 0, 0.12) 0 1px 18px 0;
  box-sizing: border-box;
  color: #3c4043;
  cursor: pointer;
  display: inline-flex;
  fill: currentcolor;
  font-family: "Google Sans", Roboto, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  height: 48px;
  justify-content: center;
  letter-spacing: 0.25px;
  line-height: normal;
  max-width: 100%;
  overflow: visible;
  padding: 2px 24px;
  position: relative;
  text-align: center;
  text-transform: none;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1),
    opacity 15ms linear 30ms, transform 270ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: auto;
  will-change: transform, opacity;
  z-index: 0;
}

.button-17:hover {
  background: #f6f9fe;
  color: #174ea6;
}

.button-17:active {
  box-shadow: 0 4px 4px 0 rgb(60 64 67 / 30%),
    0 8px 12px 6px rgb(60 64 67 / 15%);
  outline: none;
}

.button-17:focus {
  outline: none;
  border: 2px solid #4285f4;
}

.button-17:not(:disabled) {
  box-shadow: rgba(60, 64, 67, 0.3) 0 1px 3px 0,
    rgba(60, 64, 67, 0.15) 0 4px 8px 3px;
}

.button-17:not(:disabled):hover {
  box-shadow: rgba(60, 64, 67, 0.3) 0 2px 3px 0,
    rgba(60, 64, 67, 0.15) 0 6px 10px 4px;
}

.button-17:not(:disabled):focus {
  box-shadow: rgba(60, 64, 67, 0.3) 0 1px 3px 0,
    rgba(60, 64, 67, 0.15) 0 4px 8px 3px;
}

.button-17:not(:disabled):active {
  box-shadow: rgba(60, 64, 67, 0.3) 0 4px 4px 0,
    rgba(60, 64, 67, 0.15) 0 8px 12px 6px;
}

.button-17:disabled {
  box-shadow: rgba(60, 64, 67, 0.3) 0 1px 3px 0,
    rgba(60, 64, 67, 0.15) 0 4px 8px 3px;
}

.dropdown-navbar {
  background-color: #333;
  color: white;
  padding: 10px;
  position: relative;
}

.navbar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.stepper-icon {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 20px;
  width: 30px;
}

.stepper-icon span {
  height: 3px;
  background-color: white;
  transition: transform 0.3s, opacity 0.3s;
}

.stepper-icon.active span:nth-child(1) {
  transform: translateY(8px) rotate(45deg);
}

.stepper-icon.active span:nth-child(2) {
  opacity: 0;
}

.stepper-icon.active span:nth-child(3) {
  transform: translateY(-8px) rotate(-45deg);
}

.navbar-items {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
}

.navbar-items.open {
  max-height: 200px; /* Adjust the maximum height of the dropdown menu */
}

.navbar-items li {
  padding: 10px;
  border-bottom: 1px solid #444;
}

.navbar-items li:last-child {
  border-bottom: none;
}

.navbar-items li:hover {
  background-color: #444;
}

nav {
  background-color: #333;
  color: white;
  padding: 10px;
}

ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

li {
  margin-right: 20px;
  cursor: pointer;
}

/* .dropdown {
  position: relative;
}

.dropdown-content {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #f9f9f9;
  min-width: 120px;
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}
 */

 .dropdown {
  position: relative;
}

.dropdown-content {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #333; /* Change the background color */
  min-width: 180px; /* Increase the minimum width */
  z-index: 1;
  border-radius: 4px; /* Add rounded corners */
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1); /* Add a subtle box-shadow */
  opacity: 0; /* Start with zero opacity */
  transform: translateY(-10px); /* Move the dropdown slightly up */

  /* Add CSS transitions for smooth animation */
  transition: opacity 0.3s ease, transform 0.3s ease;
}

/* Show the dropdown when it's open */
.dropdown:hover .dropdown-content {
  display: block;
  opacity: 1;
  transform: translateY(0);
}

/* Style for dropdown list items */
.dropdown-content a {
  color: white; /* Change the text color */
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  transition: background-color 0.3s ease;
}

/* Hover effect for list items */
.dropdown-content a:hover {
  background-color: #444; /* Change the hover background color */
}

