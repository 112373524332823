/* @import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap'); */

@font-face {
  font-family: "autodestruct-bb";
  src: local("autodestruct-bb"),
    url(../assets/fonts/autodestruct-bb/autodestructbb_reg.ttf)
      format("truetype");
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  box-sizing: border-box;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  text-decoration: none !important;
  color: #0b093b;
}

a:hover {
  color: #75c366;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px #fff inset;
  /* Change the color to your own background color */
  box-shadow: transparent;
  -webkit-text-fill-color: #fff;
}

body {
  background: #fff;
  color: #0b093b;
  font-family: "Khula", sans-serif;
  overflow-x: hidden;
  font-size: 16px;
  font-weight: 400;
}

.container {
  width: 100%;
  max-width: 1220px;
  margin: 0 auto;
  padding: 0px 30px;
}

.content {
  font-family: "autodestruct-bb";
}

@media only screen and (max-width: 859px) {
  .container {
    padding: 0 15px;
  }
}

.regular {
  font-weight: 400 !important;
}

.semiBold {
  font-weight: 600 !important;
}

.extraBold {
  font-weight: 800 !important;
}

/* HELLPERS */
.textCenter {
  text-align: center;
}

.animate {
  transition: 0.3s ease;
}

.pointer {
  cursor: pointer;
}

.relative {
  position: relative;
}

.textCenter {
  text-align: center;
}

.textLeft {
  text-align: left;
}

.textRight {
  text-align: right;
}

.x100 {
  width: 100%;
}

.active {
  border-bottom: 2px solid #75c366;
  color: #75c366;
}

.tag {
  padding: 5px 10px;
  margin: 10px 0;
}

.shadow {
  box-shadow: 0 0 11px rgba(237, 232, 237, 0.5);
  -webkit-box-shadow: 0 0 11px rgba(237, 232, 237, 0.5);
  -moz-box-shadow: 0 0 11px rgba(237, 232, 237, 0.5);
}

/* FLEXBOX */
.flex {
  display: flex;
}

.flexSpaceCenter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flexNullCenter {
  display: flex;
  align-items: center;
}

.flexColumn {
  flex-direction: column;
}

.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flexSpaceNull {
  display: flex;
  justify-content: space-between;
}

/* FONT SIZES */
.font11 {
  font-size: 0.688rem;
}

.font12 {
  font-size: 0.75rem;
}

.font13 {
  font-size: 0.813rem;
}

.font14 {
  font-size: 0.875rem;
}

.font15 {
  font-size: 0.938rem;
}

.font18 {
  font-size: 1.125rem;
}

.font20 {
  font-size: 1.25rem;
}

.font25 {
  font-size: 1.563rem;
}

.font30 {
  font-size: 1.875rem;
}

.font40 {
  font-size: 2.5rem;
}

.font60 {
  font-size: 3.75rem;
  line-height: 4.5rem;
}

@media only screen and (max-width: 859px) {
  .font11 {
    font-size: 0.688rem;
  }

  .font12 {
    font-size: 0.688rem;
  }

  .font13 {
    font-size: 0.75rem;
  }

  .font14 {
    font-size: 0.813rem;
  }

  .font15 {
    font-size: 0.813rem;
  }

  .font18 {
    font-size: 0.938rem;
  }

  .font20 {
    font-size: 1.125rem;
  }

  .font25 {
    font-size: 1.25rem;
  }

  .font30 {
    font-size: 1.5rem;
  }

  .font40 {
    font-size: 1.875rem;
  }

  .font60 {
    font-size: 2.8rem;
  }
}

/* BORDER RADIUS */
.radius8 {
  border-radius: 1rem;
}

.radius6 {
  border-radius: 0.6rem;
}

/* COLORS */
.darkColor {
  color: #0b093b;
}

.purpleColor {
  color: #7620ff;
}

.orangeColor {
  color: #f2b300;
}

.lightColor {
  color: #f5f5f5;
}

.coralColor {
  color: #c4ffff;
}

.greenColor {
  color: #49cb86;
}

.greyColor {
  color: #707070;
}

.whiteColor {
  color: #fff;
}

/* BACKGROUNDS */
.darkBg {
  background-color: #75c366;
}

.purpleBg {
  background-color: #7620ff;
}

.orangeBg {
  background-color: #f2b300;
}

.lightBg {
  background-color: #f5f5f5;
}

.coralBg {
  background-color: #c4ffff;
}

.greenBg {
  background-color: #49cb86;
}

.greyBg {
  background-color: #707070;
}

.whiteBg {
  background-color: #fff;
}

.servicesDiv {
  height: 250px;
  transition: 0.7s;
}

.servicesDiv:hover {
  transform: scale(1.1);
}

.serviceContainer {
  margin-top: 50px;
}

.text-overlay {
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* background-color: rgba(0, 0, 0, 0.7); Add a semi-transparent background */
  padding: 10px 20px;
  color: white;
  font-size: 50px;
  font-weight: bold;
  text-align: center;
  text-decoration: underline;
}

/* Optional styling for the text */
.text-overlay h2 {
  margin: 0;
}
